import { AiFillSchedule, AiOutlineQrcode } from 'react-icons/ai'
import { BiCurrentLocation } from 'react-icons/bi'
import { BsDatabaseFillAdd, BsPeopleFill } from 'react-icons/bs'
import { CgProfile } from 'react-icons/cg'
import { FaListUl, FaPlay, FaWarehouse } from 'react-icons/fa'
import { FaMoneyCheck } from 'react-icons/fa6'
import { GiLaserWarning, GiRoundStrawBale } from 'react-icons/gi'
import { IoNotificationsCircleOutline } from 'react-icons/io5'
import { LuLineChart, LuPackage, LuPieChart } from 'react-icons/lu'
import { MdFavorite, MdSell, MdWorkHistory, MdCalculate, MdOutlineWork  } from 'react-icons/md'
import { SiMicrosoftexcel } from 'react-icons/si'

export const linksAdministratorAll = [
    {
        isToggle: true,
        icon: CgProfile,
        path: '/Administrator',
        text: 'Профиль',
    },
    {
        isToggle: true,
        icon: SiMicrosoftexcel,
        path: '/Administrator/excel',
        text: 'Excel',
    },
    {
        isToggle: true,
        icon: MdOutlineWork,
        path: '/Administrator/shifts/editShift',
        text: 'Смены',
        additions: [
            {
                icon: MdWorkHistory,
                path: '/Administrator/shifts/editShift',
                text: 'История',
            },
            {
                icon: BiCurrentLocation,
                path: '/Administrator/shifts/currentShifts',
                text: 'Текущие',
            }
        ]
    },
    {
        isToggle: true,
        icon: AiOutlineQrcode,
        path: '/Administrator/timespan/codes',
        text: 'КОД доступа',
    },
    {
        isToggle: true,
        icon: GiLaserWarning,
        path: '/Administrator/lasers',
        text: 'Лазеры',
    },
    {
        isToggle: true,
        icon: BsPeopleFill,
        path: '/Administrator/Workers',
        text: 'Сотрудники',
    },
    {
        isToggle: true,
        icon: FaListUl,
        path: '/Administrator/ExtraWorks',
        text: 'Список работы',
    },
    {
        isToggle: true,
        icon: IoNotificationsCircleOutline,
        path: '/Administrator/Notifications',
        text: 'Уведомления',
    },
    {
        isToggle: true,
        icon: FaMoneyCheck,
        path: '/Administrator/salary',
        text: 'Зарплата',
    },
    {
        isToggle: true,
        icon: AiFillSchedule,
        path: '/Administrator/workschedule',
        text: 'График работы',
    },
    {
        isToggle: true,
        icon: FaWarehouse,
        path: '/Administrator/productWarehouse',
        text: 'Готовая продукция',
    },
    {
        isToggle: true,
        icon: GiRoundStrawBale,
        path: '/Administrator/rawMaterialWarehouse',
        text: 'Расходники',
    },
    {
        isToggle: true,
        icon: MdCalculate,
        path: '/Administrator/calculateOrderCost',
        text: 'Калькулятор',
        additions: [
            {
                icon: MdCalculate,
                path: '/Administrator/calculateOrderCost',
                text: 'Калькулятор',
            },
            {
                icon: MdCalculate,
                path: '/Administrator/calculateOrderCost/edit',
                text: 'Редактировать',
            }
        ]
    },
];

export const linksAdministratorCode = [
    {
        isToggle: true,
        icon: AiOutlineQrcode,
        path: '/Administrator/timespan/codes',
        text: 'КОД доступа',
    },
];

export const linksEmployee = [
    {
        isToggle: true,
        icon: CgProfile,
        path: '/Employee',
        text: 'Профиль',
    },
    {
        isToggle: true,
        icon: MdWorkHistory,
        path: '/Employee/shifts/History',
        text: 'Смены',
    },
    {
        isToggle: true,
        icon: MdFavorite,
        path: '/Employee/extraworks/favorites',
        text: 'Избранные работы',
    },
    {
        isToggle: true,
        icon: BiCurrentLocation,
        path: '/Employee/shifts/current',
        text: 'Текущая смена',
    },
    {
        isToggle: true,
        icon: FaPlay,
        path: '/Employee/startShift',
        text: 'Начать смену',
    },
];

export const linksManager = [
    {
        isToggle: true,
        icon: CgProfile,
        path: '/Manager',
        text: 'Профиль',
    },
    {
        isToggle: true,
        icon: MdSell,
        path: '/Manager/sellProduct',
        text: 'Оформить продукцию',
    },
    {
        isToggle: true,
        icon: MdSell,
        path: '/Manager/sellProduct/new',
        text: 'Оформить продукцию (новое)',
    },
    {
        isToggle: true,
        icon: BsDatabaseFillAdd,
        path: '/Manager/lead',
        text: 'Оформить лида',
    },
];

export const linksAnalyze = [
    {
        isToggle: true,
        icon: CgProfile,
        path: '/Analyze',
        text: 'Профиль',
    },
    {
        isToggle: true,
        icon: LuLineChart,
        path: '/Analyze/dynamicOrder',
        text: 'Динамика заказов',
    },
    {
        isToggle: true,
        icon: LuPackage,
        path: '/Analyze/sell',
        text: 'Продажи',
    },
    {
        isToggle: true,
        icon: LuPieChart,
        path: '/Analyze/profitability',
        text: 'Рентабельность',
    },
];